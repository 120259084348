import { useState, useEffect } from 'react';

export const useToggleClick = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const handleToggleClick = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('openMenu');
    } else {
      document.body.classList.remove('openMenu');
    }
    if (window.innerWidth > 768) {
        const handleScroll = () => {
          const header = document.getElementById("Header");
          const sticky = 100;
  
          if (window.pageYOffset > sticky) {
            header.classList.add("hide");
            document.getElementById('Header').style.display = 'none';
            document.getElementById('sticky-header').classList.add('show-sticky-header');
            document.getElementById('sticky-header').style.display = 'block';
          } else {
            header.classList.remove("sticky");
            document.body.classList.remove("sticky");
            document.getElementById('sticky-header').style.display = 'none';
            document.getElementById('Header').style.display = 'block';
            document.getElementById('sticky-header').classList.remove('show-sticky-header');
          }
        };
  
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
  
        // Clean up the scroll event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }
  }, [isMenuOpen]);

  return handleToggleClick;
};
