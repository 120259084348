import React, { useEffect, useState } from "react";
//import logo from './logo.png';
//import { Helmet } from "react-helmet";


export default function Stickyheader() {

  const [logo, setLogo] = useState('');

  useEffect(() => {
    loadLogo();
 }, []);


 const loadLogo=()=>{
  fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/logo", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          const data = result.data;
          setLogo(data);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}

    return (
        <header className="main_header" id="sticky-header" style={{ display: "none" }} >
    <div className="main-header">
      <div className="container">
        <div className="bottom-header">
          <div className="logo-cont">
            <img src={logo.image} alt="logo" />
          </div>
          <ul className="main-menu">
            <li>
              <a href="/">Home</a>
            </li>
         
            <li>
              <a href="/menu">Menu</a>
            </li>
           
          </ul>
          <div className="toogle-bar">
            <div id="toggleNav1" >
              <span className="MenuBtn">
                <i />
                <i />
                <i />
              </span>
            </div>
            <div id="megaMenu1" className="menuNavigation" style={{}}>
              <div className="container">
                <ul className="listItems">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  
                  <li>
                    <a href="/menu">Menu</a>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
    )
  
}
