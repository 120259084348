import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";


class Loginpage extends Component {
   
    // componentDidMount() {
    //     //initialize datatable
    //     function formValidation() {
    //         window.addEventListener('load', function () {
    //             // Fetch all the forms we want to apply custom Bootstrap validation styles to
    //             var forms = document.getElementsByClassName('needs-validation');
    //             // Loop over them and prevent submission
    //             var validation = Array.prototype.filter.call(forms, function (form) {
    //                 form.addEventListener('submit', function (event) {
    //                     if (form.checkValidity() === false) {
    //                         event.preventDefault();
    //                         event.stopPropagation();
    //                     }

    //                     form.classList.add('was-validated');
    //                     history.push('/Dashboard')
    //                 }, false);
    //             });

    //         }, false);
    //     }
    //     formValidation();
    // }



    constructor(props, context) {
        super(props, context);
      
        
        this.handleShow1 = this.handleShow1.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
            username: '',
            password: '',
        };
    }
    handleShow1() {
        this.setState({ show1: true });
    }
    handleClose() {
        this.setState({ show1: false });
    }
    
  handleLogin = async (event) => {
    const { username, password } = this.state;
    // const { history } = this.props;
    fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/login", {
           method: "POST",
           body: JSON.stringify({
             user_name: username,
             password: password,
           }),
           headers: {
             Accept: "application/json",
             "Content-type": "application/json; charset=UTF-8",
           },
         })
           .then((res) => res.json())
           .then((result) => {
            //console.log(result);

                if (result.data!=="") {
                    //console.log(response);return;
                    // Successful login, handle authentication state (e.g., set a token in local storage)
                    localStorage.setItem('token', result.data.id);
                    window.location.href = '/menu-grid';

                } else {
                    // Handle unsuccessful login
                    this.setState({ error: 'Invalid username or password' });
                }
           })
         .catch((err) => {
            this.setState({ error: 'Invalid username or password' });
           console.log(err.message);
         });
    
        

   
  };

    render() {
        const { username, password, error } = this.state;
        return (
            <div className="ms-content-wrapper ms-auth">
                <div className="ms-auth-container">
                    <div className="ms-auth-col">
                        <div className="ms-auth-bg" />
                    </div>
                    <div className="ms-auth-col">
                        <div className="ms-auth-form">
                            <form className="needs-validation" noValidate>
                                <h3>Login</h3>
                                <p>Please enter your email and password to continue</p>
                                <div className="mb-3">
                                    <label htmlFor="username">Email Address</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="username"  value={username} placeholder="Email Address" required  onChange={(e) => this.setState({ username: e.target.value })}/>
                                        <div className="invalid-feedback">Please provide a valid email.</div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-group">
                                        <input type="password" value={password} className="form-control" id="password" placeholder="Password" required onChange={(e) => this.setState({ password: e.target.value })}/>
                                        <div className="invalid-feedback">Please provide a password.</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    {/* <label className="ms-checkbox-wrap">
                                        <input className="form-check-input" type="checkbox" defaultValue /> <i className="ms-checkbox-check" />
                                    </label> <span> Remember Password </span>
                                    <label className="d-block mt-3"><Link to="/login" className="btn-link" onClick={this.handleShow1}>Forgot Password?</Link>
                                    </label> */}
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>
                                <button className="btn btn-primary mt-4 d-block w-100" type="button" onClick={this.handleLogin}>Sign In</button>
                                {/* <p className="mb-0 mt-3 text-center">Don't have an account? <Link className="btn-link" to="/default-register">Create Account</Link>
                                </p> */}
                            </form>
                        </div>
                    </div>
                </div>
                <Modal className="modal-min" show={this.state.show1} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="text-center">
                        <button type="button" className="close" onClick={this.handleClose}><span aria-hidden="true">×</span></button>
                        <i className="flaticon-secure-shield d-block" />
                        <h1>Forgot Password?</h1>
                        <p>Enter your email to recover your password</p>
                        <form method="post">
                            <div className="ms-form-group has-icon">
                                <input type="text" placeholder="Email Address" className="form-control" name="forgot-password" /> <i className="material-icons">email</i>
                            </div>
                            <button type="submit" className="btn btn-primary shadow-none">Reset Password</button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

export default Loginpage;