import React, { useState } from 'react';
import FeedbackLogo from '../assets/img/feedbacklogo.png';
import Header from './Header.js';
import Stickyheader from './Stickyheader.js';
import ThankyouLogo from '../assets/img/haikuthankyou-logo.png';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Feedback = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    branch:'',
    name: '',
    email: '',
    phone: '',
    visitus: '',
    discovery: '',
    experienceRating: '',
    foodQuality: '',
    serviceQuality: '',
    atmosphere: '',
    favoriteDish: '',
    improvement: '',
    recommend: '',
    comments: '',
  });

  const formatDate = (date) => {
    return date
      ? date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '';
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({
      ...formData,
      visitus: date,  // Updating the visitus field in formData
    });
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      
      const formattedDate = formatDate(formData.visitus);

      const formDataObj = new FormData();
      Object.keys(formData).forEach(key => {
        formDataObj.append(key, key === 'visitus' ? formattedDate : formData[key]);
      });

      const response = await axios.post('https://apimenu3.haikutheasiankitchen.com/index.php/Api/addfeedback', formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Form submitted:', response.data);
      alert('Thank You for Your Feedback.');
      window.location.href="https://haikutheasiankitchen.com";
      // Reset form data after successful submission
      setFormData({
        branch: '',
        name: '',
        email: '',
        phone: '',
        visitus: '',
        discovery: '',
        experienceRating: '',
        foodQuality: '',
        serviceQuality: '',
        atmosphere: '',
        favoriteDish: '',
        improvement: '',
        recommend: '',
        comments: '',
      });

    setSelectedDate(null); // Reset the DatePicker

    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <>
      <Header />
      <Stickyheader />
      <div className="container-fluid feedback-fluid">
        <div className="container">
          <div className="feedback-form-cont">
            <div className="flex-cont">
              <div className="left-feedback-cont">
                {/* <div className="logo-img">
                  <img src={FeedbackLogo} alt="site-logo" />
                </div> */}

                <form onSubmit={handleSubmit}>
                  <div className="feedback-form">
                    <div className="form-group top-fields ">
                     <label>Branch <span className="text-danger">*</span></label>
                     <select
                        className="form-select form-control"
                        name="branch"
                        required
                        onChange={handleInputChange}
                        style={{marginBottom:'10px'}}
                        value={formData.branch}
                      >
                        <option value="">--Select Branch--</option>
                          <option value="Banjara Hills" key="Banjara Hills">Banjara Hills</option>
                          <option value="Gachibowli" key="Gachibowli">Gachibowli</option>
                          <option value="Kokapet" key="Kokapet">Kokapet</option>
                      </select>
                     
                      <label>Name <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                      <label>Phone No</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      <label>Email </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label>When Did You Visit Us?</label><br></br>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"  // Display date and time (default format is MM/DD/YYYY, hh:mm AM/PM)
                        timeIntervals={15}  // Allows time to be selected in 15-minute increments
                        placeholderText="Date and Time"
                        className="form-control"

                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        name="visitus"
                        value={formData.visitus}
                        onChange={handleInputChange}
                      /> */}
                    </div>
                    <div className="form-group">
                      <h3>How Did You Discover Us?</h3>
                      {['A Friend’s Foodie Recommendation', 'Social Media (Instagram, Facebook, etc.)', 'Online Search (Google, Yelp)', 'Just Happened to Walk By!', 'other'].map(option => (
                 
                         <div className="d-flex" key={option}>
                         <input
                           type="radio"
                           id={`discovery-${option}`}
                           name="discovery"
                           value={option}
                           onChange={handleInputChange}
                         />
                         <label htmlFor={`discovery-${option}`}>{option}</label>
                       </div>
                      ))}
                    </div>

                    <div className="form-group">
                    <h3>Rate Your Overall Experience! <br></br>
                    (1 = “Oh No!” to 5 = “Spicy Perfection!”)</h3>
                      {['Oh No, My Taste Buds!', 'Just Okay, Like Cold Rice', 'Good, but Not a Knockout', 'Tasty! Like a Warm Bowl of Noodles', 'Spicy Perfection! I’ll Be Back!'].map(rate => (
                        <div className="d-flex" key={rate}>
                          <input
                            type="radio"
                            id={`experience-${rate}`}
                            name="experienceRating"
                            value={rate}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={`experience-${rate}`}>{rate}</label>
                        </div>
                      ))}
                    </div>
                    <div className="form-group">
                      <h3>How Did We Do?<br></br>Food Quality:</h3>
                      {['What Happened to My Dumplings?', 'Just Edible', 'Satisfying, Like a Good Spring Roll', 'Delicious! Flavor Explosion!', 'A Culinary Masterpiece!'].map(rate => (
                        <div className="d-flex" key={rate}>
                          <input
                            type="radio"
                            id={`foodQuality-${rate}`}
                            name="foodQuality"
                            value={rate}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={`foodQuality-${rate}`}>{rate}</label>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <h3>Service Quality:</h3>
                      {['Lost in Translation', 'Needs a Little More Spice', 'Good, Like a Steamed Bun', 'Quick and Friendly!', 'Exceptional Service with a Smile!'].map(rate => (
                        <div className="d-flex" key={rate}>
                          <input
                            type="radio"
                            id={`serviceQuality-${rate}`}
                            name="serviceQuality"
                            value={rate}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={`serviceQuality-${rate}`}>{rate}</label>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <h3>Atmosphere:</h3>
                      {['More Like a Food Court', 'Needs More Ambiance', 'Cozy and Inviting', 'Perfect for a Night Out!', 'A Little Slice of Asia!'].map(rate => (
                        <div className="d-flex" key={rate}>
                          <input
                            type="radio"
                            id={`atmosphere-${rate}`}
                            name="atmosphere"
                            value={rate}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={`atmosphere-${rate}`}>{rate}</label>
                        </div>
                      ))}
                    </div>
                    {/* Repeat similar structure for food quality, service quality, and atmosphere */}
                    
                    <div className="form-group">
                      <label>What Was Your Favorite Dish?</label>
                      <input
                        type="text"
                        className="form-control"
                        name="favoriteDish"
                        value={formData.favoriteDish}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label>What Can We Improve?</label>
                      <input
                        type="text"
                        className="form-control"
                        name="improvement"
                        value={formData.improvement}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <h3>Would You Recommend Us to Friends?</h3>
                      {['Yes, I’ll Spread the Word! ', 'Maybe, If They Ask.', 'No, I’d Rather Eat Plain Rice.'].map(option => (
                        <div className="d-flex" key={option}>
                          <input
                            type="radio"
                            id={option}
                            name="recommend"
                            value={option}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</label>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <label>Any Fun Stories or Additional Comments?</label>
                      <input
                        type="text"
                        className="form-control"
                        name="comments"
                        value={formData.comments}
                        onChange={handleInputChange}
                      />
                    </div>

                    <button type="submit" className="submit-feedback">
                      Submit
                    </button>
                  </div>
                </form>

                <div className="thankyou-cont">
                  <h4>🌟 Thank You for Your Feedback!<br></br> We Can’t Wait to Serve You Again! 🌟</h4>
                  <img src={ThankyouLogo} alt="thankyou-logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
