import React from 'react';
import close from './close.png';
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
  }


  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { showModal } = this.state;

    return (
    <div className={showModal ? "modal" : "modal hidden"}>
    <div className="modal-dialog modal-lg" onClick={this.closeModal}>
      <div className="modal-content">
        <button type="button" className="close" onClick={this.closeModal}>
          <img src={close} alt="close" title="close" />
        </button>
        <form>
          <input type="text" name="name" placeholder="Name" />
          <input
            type="number"
            maxLength={10}
            name="phone"
            placeholder="Phone"
          />
          <input type="email" name="email" placeholder="Email" />
          <input type="text" name="password" placeholder="Password" />
          <button type="submit" name="submit">
            REGISTER &amp; CLOSE
          </button>
        </form>
      </div>
    </div>
  </div>
      
    );
  }
}

export default Modal;
